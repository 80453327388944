@import "../_variables.scss";
html {
	font-size: 10px;
}
body {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	color: $text;
	font-size: 1.8rem;
}
textarea,
button,
input {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
}
h1 {
	font-family: 'Open Sans', sans-serif;
	font-size: 5.2rem;
	font-weight: 800;
	margin-bottom: 28px;
	line-height: 1;
	color: $heading;
	text-align: center;
	@media screen and (max-width: $bp-md - 1) {
		font-size: 4.2rem;
		margin-bottom: 20px;
	}
	@media screen and (max-width: $bp-xs - 1) {
		font-size: 3rem;
		margin-bottom: 16px;
	}
}
h2 {
	font-family: 'Open Sans', sans-serif;
	font-size: 3.7rem;
	margin-bottom: 18px;
	font-weight: 700;
	line-height: 1.1;
	color: $heading;
	text-align: center;
	@media screen and (max-width: $bp-md - 1) {
		font-size: 3.1rem;
		margin-bottom: 12px;
	}
	@media screen and (max-width: $bp-xs - 1) {
		font-size: 2.5rem;
		margin-bottom: 10px;
	}
}
h3 {
	font-family: 'Open Sans', sans-serif;
	font-size: 2.9rem;
	position: relative;
	font-weight: 700;
	margin-bottom: 16px;
	line-height: 1.1;
	color: $heading;
	@media screen and (max-width: $bp-sm - 1) {
		font-size: 2.3rem;
		margin-bottom: 14px;
	}
	@media screen and (max-width: $bp-xs - 1) {
		font-size: 2.1rem;
		margin-bottom: 12px;
	}
}
h4 {
	font-family: 'Open Sans', sans-serif;
	font-size: 2.2rem;
	position: relative;
	font-weight: 700;
	margin-bottom: 8px;
	line-height: 1.1;
	color: $heading;
	@media screen and (max-width: $bp-xs - 1) {
		font-size: 1.8rem;
	}
}
p {
	font-size: 1.8rem;
	line-height: 1.6;
	margin-bottom: 20px;
	@media screen and (max-width: $bp-xs - 1) {
		font-size: 1.6rem;
		line-height: 1.6;
		margin-bottom: 15px;
	}
}

div,
ul,
ol,
table {
	font-size: 1.8rem;
	color: $text;
}
a,
li,
td,
span,
input,
textarea,
label,
th {
	font-size: inherit;
	color: $text;
}
// .static-page__content{
// 	a{
// 		text-decoration: underline;
// 	}
// }
.p-middle {
	width: 500px;
	max-width: 100%;
	margin: auto;
	margin-bottom: 50px;
	text-align: center;
	@media screen and (max-width: $bp-xs - 1) {
		margin-bottom: 20px;
	}
}
.mainContainer {
	//Tabulky
	strong {
		font-weight: 700 !important;
	}
	table {
		display: block;
		width: 100%;
		overflow-x: auto;
		margin: 0 0;
		margin-bottom: 40px;

		thead {
			text-align: left;
			font-size: 1.6rem;
			tr {
				background-color: rgba($color: $text, $alpha: 0.16);
			}
			th {
				border: 1px solid $text;

				// &:last-child {
				// 	padding-right: 0;
				// 	text-align: right;
				// }
				padding: 10px;
				padding-left: 25px;
			}
		}
		tbody {
			tr {
				background: #fff;
			}

			tr:nth-child(odd) {
				background-color: #efefef;
			}
		}
		td {
			border: 1px solid $text;
			padding: 17px 25px;
			a {
				font-weight: 700;
			}
			p {
				margin-bottom: 0;
			}
			// &:last-child {
			// 	text-align: right;
			// 	border-right: 0;
			// }
		}
	}
	//Unorder lists
	ol,
	ul {
		margin-bottom: 40px;
		li {
			line-height: 1.6;
			margin-bottom: 5px;
		}
	}
	ul {
		li {
			position: relative;
			padding-left: 15px;

			&::before {
				position: absolute;
				content: "";
				width: 6px;
				height: 6px;
				top: .6em;
				left: 0;
				background-color: $base-color;
				border-radius: 50%;
			}
			ul {
				margin-bottom: 0;
			}
			ul > li {
				position: relative;
				padding-left: 15px;

				&::before {
					position: absolute;
					content: "";
					width: 6px;
					height: 6px;
					top: .6em;
					left: 0;
					background-color: $sec-color;
					border-radius: 50%;
				}
			}
		}
	}
	//Order lists
	ol {
		list-style-type: revert;
		li {
			margin-left: 20px;
		}
	}
	p > a {
		font-weight: 700;
		text-decoration: underline;
	}
}
