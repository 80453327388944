@import '../variables';
$submenu-width: 310px;
$submenu-background: $base-color;
$submenu-color: #fff;
$submenu-padding: 30px;
$submenu-border-radius: 10px;
$submenu-border-bottom-li-color: #707070;
$submenu-padding-li: 15px;
$submenu-font-size: 2rem;
.static-page__submenu {
	width: $submenu-width;
	max-width: 100%;
	flex-shrink: 0;
	padding-right: $submenu-padding;
	& > ul {
		width: 100%;
		background-color: $submenu-background;
		color: $submenu-color;
		border-radius: $submenu-border-radius;
		li {
			padding: 0;
			color: $submenu-color;
			padding-right: $submenu-padding-li;
			background-color: $submenu-background;
			border-radius: $submenu-border-radius;
			margin-bottom: 0;
			&:before {
				display: none;
			}
		}
		li a {
			color: inherit;
			transition: all 250ms ease-in-out;
			&:hover {
				text-decoration: underline;
			}
		}
		& > li {
			border-collapse: collapse;
			//border-bottom: 1px solid rgba($submenu-border-bottom-li-color, .23);
			color: inherit;
			&:last-child {
				border-bottom: unset;
			}
			& > a {
				display: block;
				padding: $submenu-padding-li;
				margin-bottom: 0;
				font-weight: 700;
				font-size: $submenu-font-size;
				color: inherit;
			}
			//Second layer
			ul {
				display: none;
				padding-bottom: 10px;
				li {
					padding-left: $submenu-padding;
					&.child-active,
					&.active {
						& > a {
							font-weight: 700;
							color: $text;
						}
						& > ul,
						&.child-active > ul {
							display: block;
						}
					}
					ul {
						margin-top: 0;
						padding-bottom: 0;
						li {
							padding-left: 10px;
						}
					}
				}
			}
			&.active > ul,
			&.child-active > ul {
				display: block;
			}
		}
	}
	@media (max-width: $nav-bp - 1) {
		display: none;
		width: 100%;
		padding-right: 0;
	}
}

#static-menu-mobile-trigger {
	display: none;
	// Side menu respo
	@media (max-width: $nav-bp - 1) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 0 0 20px;
		background: $submenu-background;
		width: 100%;
		border-radius: $submenu-border-radius;
		margin: 0 0 22px;
		font-weight: 700;
		color: $submenu-color;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		&:after {
			content: "";
			display: block;
			width: 48px;
			height: 48px;
			background-image: url('../img/submenu-down-white.svg');
			background-size: 50% auto;
			background-repeat: no-repeat;
			background-position: center;
			background-color: $text;
		}
		&:hover {
			background-color: $submenu-color;
			box-shadow: inset 0 0 0 2px $submenu-background;
			color: $submenu-background !important;
			&:after {
				background-color: $submenu-background;
				transition: background-color 200ms ease-in-out, color 200ms ease-in-out !important;
			}
		}
	}
}
