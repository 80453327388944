/* Colors */
/* Menu */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

* {
  box-sizing: border-box; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* Global styles TOC
	Reseter
	Fonts
	Typo
	Global classes
	Bootstrap alter
*/
/* Colors */
/* Menu */
/* Typo */
button,
input[type="submit"] {
  cursor: pointer; }

* {
  outline: none; }

a {
  text-decoration: none;
  transition: 200ms ease-in-out;
  color: #509EE2; }

li {
  font-size: inherit; }

img {
  max-width: 100%;
  height: auto; }

strong {
  font-weight: 700; }

img.no-resize {
  max-width: none; }

body .fs-40 {
  font-size: 40px; }

body .fs-36 {
  font-size: 36px; }

body .fs-34 {
  font-size: 34px; }

body .fs-30 {
  font-size: 30px; }

body .fs-24 {
  font-size: 24px; }

body .fs-20 {
  font-size: 20px; }

body .fs-18 {
  font-size: 18px; }

body .fs-16 {
  font-size: 16px; }

body .fs-15 {
  font-size: 15px; }

body .fs-14 {
  font-size: 14px; }

body .fw-300 {
  font-weight: 300; }

body .fw-400 {
  font-weight: 400; }

body .fw-500 {
  font-weight: 500; }

body .fw-700 {
  font-weight: 700; }

body .text-center {
  text-align: center; }

body .text-left {
  text-align: left; }

body .text-right {
  text-align: right; }

body .td-underline {
  text-decoration: underline; }

body .text-green {
  color: #509EE2; }

body .monserat {
  font-family: 'Montserrat', sans-serif; }

.no-border {
  border: none !important; }

.small {
  font-size: .8em; }

.big {
  font-size: 1.6em; }

.nowrap {
  white-space: nowrap; }

.turncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

@media (min-width: 992px) {
  .turncate-sm {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; } }

.w-100 {
  width: 100%; }

.uppercase {
  text-transform: uppercase; }

.td-under {
  text-decoration: underline; }

.mb-1 {
  margin-bottom: 25px; }

.mb-10 {
  margin-bottom: 10px; }

/* Global classes */
.relative {
  position: relative; }

.absolute {
  position: absolute; }

.fixed {
  position: fixed; }

.jc-center {
  justify-content: center; }

.jc-start {
  justify-content: flex-start; }

.jc-end {
  justify-content: flex-end; }

.ac-center {
  align-content: center; }

.ai-center {
  align-items: center; }

.ai-end {
  align-items: flex-end; }

.jc-space-between {
  justify-content: space-between; }

.row {
  display: flex;
  flex-wrap: wrap; }
  .row .col {
    flex-grow: 1; }
  .row .col-ng {
    flex-grow: 0; }

.ma {
  margin: auto; }

.no-margin {
  margin: 0; }

.no-margin-left {
  margin-left: 0; }

.no-margin-right {
  margin-right: 0; }

.no-margin-top {
  margin-top: 0; }

.no-margin-bottom {
  margin-bottom: 0; }

.no-padding {
  padding: 0; }

.no-padding-left {
  padding-left: 0; }

.no-padding-right {
  padding-right: 0; }

.no-padding-top {
  padding-top: 0; }

.no-padding-bottom {
  padding-bottom: 0; }

.flex {
  display: flex; }

.flex-grow {
  flex-grow: 1; }

.flex-wrap {
  flex-wrap: wrap; }

/* Own bootstrap alternative */
.container-small,
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 100%;
  width: 100%; }

.col-1 {
  padding: 0 10px;
  width: calc((1 / 12) * 100%); }

.col-2 {
  padding: 0 10px;
  width: calc((2 / 12) * 100%); }

.col-3 {
  padding: 0 10px;
  width: calc((3 / 12) * 100%); }

.col-4 {
  padding: 0 10px;
  width: calc((4 / 12) * 100%); }

.col-5 {
  padding: 0 10px;
  width: calc((5 / 12) * 100%); }

.col-6 {
  padding: 0 10px;
  width: calc((6 / 12) * 100%); }

.col-7 {
  padding: 0 10px;
  width: calc((7 / 12) * 100%); }

.col-8 {
  padding: 0 10px;
  width: calc((8 / 12) * 100%); }

.col-9 {
  padding: 0 10px;
  width: calc((9 / 12) * 100%); }

.col-10 {
  padding: 0 10px;
  width: calc((10 / 12) * 100%); }

.col-11 {
  padding: 0 10px;
  width: calc((11 / 12) * 100%); }

.col-12 {
  padding: 0 10px;
  width: calc((12 / 12) * 100%); }

@media (min-width: 768px) {
  .container-small,
  .container {
    width: 750px; }
  .col-xs-1 {
    width: calc((1 / 12) * 100%); }
  .col-xs-2 {
    width: calc((2 / 12) * 100%); }
  .col-xs-3 {
    width: calc((3 / 12) * 100%); }
  .col-xs-4 {
    width: calc((4 / 12) * 100%); }
  .col-xs-5 {
    width: calc((5 / 12) * 100%); }
  .col-xs-6 {
    width: calc((6 / 12) * 100%); }
  .col-xs-7 {
    width: calc((7 / 12) * 100%); }
  .col-xs-8 {
    width: calc((8 / 12) * 100%); }
  .col-xs-9 {
    width: calc((9 / 12) * 100%); }
  .col-xs-10 {
    width: calc((10 / 12) * 100%); }
  .col-xs-11 {
    width: calc((11 / 12) * 100%); }
  .col-xs-12 {
    width: calc((12 / 12) * 100%); } }

@media (min-width: 992px) {
  .container-small,
  .container {
    width: 970px; }
  .col-sm-1 {
    width: calc((1 / 12) * 100%); }
  .col-sm-2 {
    width: calc((2 / 12) * 100%); }
  .col-sm-3 {
    width: calc((3 / 12) * 100%); }
  .col-sm-4 {
    width: calc((4 / 12) * 100%); }
  .col-sm-5 {
    width: calc((5 / 12) * 100%); }
  .col-sm-6 {
    width: calc((6 / 12) * 100%); }
  .col-sm-7 {
    width: calc((7 / 12) * 100%); }
  .col-sm-8 {
    width: calc((8 / 12) * 100%); }
  .col-sm-9 {
    width: calc((9 / 12) * 100%); }
  .col-sm-10 {
    width: calc((10 / 12) * 100%); }
  .col-sm-11 {
    width: calc((11 / 12) * 100%); }
  .col-sm-12 {
    width: calc((12 / 12) * 100%); } }

@media (min-width: 1200px) {
  .container-small,
  .container {
    width: 1170px; }
  .col-md-1 {
    width: calc((1 / 12) * 100%); }
  .col-md-2 {
    width: calc((2 / 12) * 100%); }
  .col-md-3 {
    width: calc((3 / 12) * 100%); }
  .col-md-4 {
    width: calc((4 / 12) * 100%); }
  .col-md-5 {
    width: calc((5 / 12) * 100%); }
  .col-md-6 {
    width: calc((6 / 12) * 100%); }
  .col-md-7 {
    width: calc((7 / 12) * 100%); }
  .col-md-8 {
    width: calc((8 / 12) * 100%); }
  .col-md-9 {
    width: calc((9 / 12) * 100%); }
  .col-md-10 {
    width: calc((10 / 12) * 100%); }
  .col-md-11 {
    width: calc((11 / 12) * 100%); }
  .col-md-12 {
    width: calc((12 / 12) * 100%); } }

@media (min-width: 1450px) {
  .container-small,
  .container {
    width: 1400px; }
  .col-lg-1 {
    width: calc((1 / 12) * 100%); }
  .col-lg-2 {
    width: calc((2 / 12) * 100%); }
  .col-lg-3 {
    width: calc((3 / 12) * 100%); }
  .col-lg-4 {
    width: calc((4 / 12) * 100%); }
  .col-lg-5 {
    width: calc((5 / 12) * 100%); }
  .col-lg-6 {
    width: calc((6 / 12) * 100%); }
  .col-lg-7 {
    width: calc((7 / 12) * 100%); }
  .col-lg-8 {
    width: calc((8 / 12) * 100%); }
  .col-lg-9 {
    width: calc((9 / 12) * 100%); }
  .col-lg-10 {
    width: calc((10 / 12) * 100%); }
  .col-lg-11 {
    width: calc((11 / 12) * 100%); }
  .col-lg-12 {
    width: calc((12 / 12) * 100%); } }

table {
  margin-bottom: 20px;
  font-size: 16px; }
  table td {
    padding: 10px 25px; }

.table-stripped tr:nth-child(odd) {
  background: #f2f2f2; }

.table-full {
  width: 100%; }

@media (max-width: 555px) {
  .hidden-xxs-down {
    display: none; }
  table {
    font-size: 14px; }
    table td {
      padding: 10px; } }

@media (max-width: 767px) {
  .hidden-xs-down {
    display: none; } }

@media (max-width: 991px) {
  .hidden-sm-down {
    display: none; } }

@media (max-width: 1199px) {
  .hidden-md-down {
    display: none; } }

@media (max-width: 1449px) {
  .hidden-lg-down {
    display: none; } }

.d-none {
  display: none;
  visibility: hidden;
  opacity: 0;
  z-index: -2000; }

.visible-only-xxs,
.visible-only-xs,
.visible-only-sm,
.visible-only-md,
.visible-only-lg {
  display: none; }

@media (max-width: 555px) {
  .visible-only-xxs {
    display: inline-block; } }

@media screen and (min-width: 556px) and (max-width: 767px) {
  .visible-only-xs {
    display: inline-block; } }

@media screen and (min-width: 768px) and (max-width: 991px) {
  .visible-only-xs {
    display: inline-block; } }

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .visible-only-sm {
    display: inline-block; } }

@media screen and (min-width: 1200px) and (max-width: 1449px) {
  .visible-only-md {
    display: inline-block; } }

.btn-mobile {
  display: none; }

@media (max-width: 767px) {
  .btn-desktop {
    display: none; }
  .btn-mobile {
    display: table; } }

@media (max-width: 1749px) {
  .flex-wrap-xl {
    flex-wrap: wrap; } }

@media (max-width: 1449px) {
  .flex-wrap-lg {
    flex-wrap: wrap; } }

@media (max-width: 1199px) {
  .flex-wrap-md {
    flex-wrap: wrap; } }

@media (max-width: 991px) {
  .flex-wrap-sm {
    flex-wrap: wrap; } }

@media (max-width: 767px) {
  .flex-wrap-xs {
    flex-wrap: wrap; } }

@media (max-width: 555px) {
  .flex-wrap-xxs {
    flex-wrap: wrap; } }

@media (max-width: 354px) {
  .flex-wrap-xxxs {
    flex-wrap: wrap; } }

/* Colors */
/* Menu */
html {
  font-size: 10px; }

body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: #333;
  font-size: 1.8rem; }

textarea,
button,
input {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400; }

h1 {
  font-family: 'Open Sans', sans-serif;
  font-size: 5.2rem;
  font-weight: 800;
  margin-bottom: 28px;
  line-height: 1;
  color: #172939;
  text-align: center; }
  @media screen and (max-width: 1199px) {
    h1 {
      font-size: 4.2rem;
      margin-bottom: 20px; } }
  @media screen and (max-width: 767px) {
    h1 {
      font-size: 3rem;
      margin-bottom: 16px; } }

h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 3.7rem;
  margin-bottom: 18px;
  font-weight: 700;
  line-height: 1.1;
  color: #172939;
  text-align: center; }
  @media screen and (max-width: 1199px) {
    h2 {
      font-size: 3.1rem;
      margin-bottom: 12px; } }
  @media screen and (max-width: 767px) {
    h2 {
      font-size: 2.5rem;
      margin-bottom: 10px; } }

h3 {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.9rem;
  position: relative;
  font-weight: 700;
  margin-bottom: 16px;
  line-height: 1.1;
  color: #172939; }
  @media screen and (max-width: 991px) {
    h3 {
      font-size: 2.3rem;
      margin-bottom: 14px; } }
  @media screen and (max-width: 767px) {
    h3 {
      font-size: 2.1rem;
      margin-bottom: 12px; } }

h4 {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.2rem;
  position: relative;
  font-weight: 700;
  margin-bottom: 8px;
  line-height: 1.1;
  color: #172939; }
  @media screen and (max-width: 767px) {
    h4 {
      font-size: 1.8rem; } }

p {
  font-size: 1.8rem;
  line-height: 1.6;
  margin-bottom: 20px; }
  @media screen and (max-width: 767px) {
    p {
      font-size: 1.6rem;
      line-height: 1.6;
      margin-bottom: 15px; } }

div,
ul,
ol,
table {
  font-size: 1.8rem;
  color: #333; }

a,
li,
td,
span,
input,
textarea,
label,
th {
  font-size: inherit;
  color: #333; }

.p-middle {
  width: 500px;
  max-width: 100%;
  margin: auto;
  margin-bottom: 50px;
  text-align: center; }
  @media screen and (max-width: 767px) {
    .p-middle {
      margin-bottom: 20px; } }

.mainContainer strong {
  font-weight: 700 !important; }

.mainContainer table {
  display: block;
  width: 100%;
  overflow-x: auto;
  margin: 0 0;
  margin-bottom: 40px; }
  .mainContainer table thead {
    text-align: left;
    font-size: 1.6rem; }
    .mainContainer table thead tr {
      background-color: rgba(51, 51, 51, 0.16); }
    .mainContainer table thead th {
      border: 1px solid #333;
      padding: 10px;
      padding-left: 25px; }
  .mainContainer table tbody tr {
    background: #fff; }
  .mainContainer table tbody tr:nth-child(odd) {
    background-color: #efefef; }
  .mainContainer table td {
    border: 1px solid #333;
    padding: 17px 25px; }
    .mainContainer table td a {
      font-weight: 700; }
    .mainContainer table td p {
      margin-bottom: 0; }

.mainContainer ol,
.mainContainer ul {
  margin-bottom: 40px; }
  .mainContainer ol li,
  .mainContainer ul li {
    line-height: 1.6;
    margin-bottom: 5px; }

.mainContainer ul li {
  position: relative;
  padding-left: 15px; }
  .mainContainer ul li::before {
    position: absolute;
    content: "";
    width: 6px;
    height: 6px;
    top: .6em;
    left: 0;
    background-color: #509EE2;
    border-radius: 50%; }
  .mainContainer ul li ul {
    margin-bottom: 0; }
  .mainContainer ul li ul > li {
    position: relative;
    padding-left: 15px; }
    .mainContainer ul li ul > li::before {
      position: absolute;
      content: "";
      width: 6px;
      height: 6px;
      top: .6em;
      left: 0;
      background-color: #000;
      border-radius: 50%; }

.mainContainer ol {
  list-style-type: revert; }
  .mainContainer ol li {
    margin-left: 20px; }

.mainContainer p > a {
  font-weight: 700;
  text-decoration: underline; }

/* Colors */
/* Menu */
.icon:before {
  content: "";
  width: 1em;
  height: 1em;
  vertical-align: middle;
  display: inline-block;
  margin-right: 1em;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; }

.icon.icon-2x:before {
  width: 2em;
  height: 2em; }

.icon.icon-3x:before {
  width: 3em;
  height: 3em; }

.icon.icon-5x:before {
  width: 5em;
  height: 5em; }

.icon.icon-marker::before {
  background-image: url("../img/marker.svg"); }

/* Fonts */
/*
@font-face {
	font-family: 'Montserrat';
	src: url('../../_fonts/MontserratA/montserrat-light.eot'); 
	src: url('../../_fonts/MontserratA/montserrat-light.woff') format('woff'),
		url('../../_fonts/MontserratA/montserrat-light.ttf') format('truetype'),
		url('../../_fonts/MontserratA/montserrat-light.svg') format('svg'); 
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat';
	src: url('../../_fonts/MontserratA/montserrat-regular.eot'); 
	src: url('../../_fonts/MontserratA/montserrat-regular.woff') format('woff'),
		url('../../_fonts/MontserratA/montserrat-regular.ttf') format('truetype'),
		url('../../_fonts/MontserratA/montserrat-regular.svg') format('svg'); 
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat';
	src: url('../../_fonts/MontserratA/montserrat-mediumitalic.eot'); 
	src: url('../../_fonts/MontserratA/montserrat-mediumitalic.woff') format('woff'),
		url('../../_fonts/MontserratA/montserrat-mediumitalic.ttf') format('truetype'),
		url('../../_fonts/MontserratA/montserrat-mediumitalic.svg') format('svg'); 
	font-weight: 400;
	font-style: italic;
}
@font-face {
	font-family: 'Montserrat';
	src: url('../../_fonts/MontserratA/montserrat-medium.eot'); 
	src: url('../../_fonts/MontserratA/montserrat-medium.woff') format('woff'),
		url('../../_fonts/MontserratA/montserrat-medium.ttf') format('truetype'),
		url('../../_fonts/MontserratA/montserrat-medium.svg') format('svg'); 
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat';
	src: url('../../_fonts/MontserratA/montserrat-semibold.eot'); 
	src: url('../../_fonts/MontserratA/montserrat-semibold.woff') format('woff'),
		url('../../_fonts/MontserratA/montserrat-semibold.ttf') format('truetype'),
		url('../../_fonts/MontserratA/montserrat-semibold.svg') format('svg'); 
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat';
	src: url('../../_fonts/MontserratA/montserrat-bold.eot'); 
	src: url('../../_fonts/MontserratA/montserrat-bold.woff') format('woff'),
		url('../../_fonts/MontserratA/montserrat-bold.ttf') format('truetype'),
		url('../../_fonts/MontserratA/montserrat-bold.svg') format('svg'); 
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Bebas Neue';
	src: url('../../_fonts/BebasNeue/BebasNeue-Light.otf') format('opentype'),
		url('../../_fonts/BebasNeue/BebasNeue-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'Bebas Neue';
	src: url('../../_fonts/BebasNeue/BebasNeue-Regular.otf') format('opentype'),
		url('../../_fonts/BebasNeue/BebasNeue-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Bebas Neue';
	src: url('../../_fonts/BebasNeue/BebasNeue-Bold.otf') format('opentype'),
		url('../../_fonts/BebasNeue/BebasNeue-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}
*/
/* Colors */
/* Menu */
a.fill-link {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2; }

body section .btn-std,
.btn-std {
  background-color: #509EE2;
  color: #fff;
  display: table;
  line-height: 1;
  margin: 10px auto;
  padding: 16px 26px;
  text-align: center;
  max-width: 100%;
  border-radius: 10px;
  position: relative;
  z-index: 2;
  transition: background-color 200ms ease-in-out, color 200ms ease-in-out !important; }
  body section .btn-std.btn-reve,
  .btn-std.btn-reve {
    background: transparent; }
  body section .btn-std.btn-orange,
  .btn-std.btn-orange {
    color: #333;
    background: linear-gradient(270deg, #ffb347 0%, #ffcc33 100%); }
  body section .btn-std.btn-left,
  .btn-std.btn-left {
    margin: 10px auto 10px 0; }
  body section .btn-std.btn-right,
  .btn-std.btn-right {
    margin: 10px 0 10px auto; }
  body section .btn-std.btn-center,
  .btn-std.btn-center {
    margin: 10px auto; }
  body section .btn-std:hover,
  .btn-std:hover {
    background-color: #fff;
    box-shadow: inset 0 0 0 2px #509EE2;
    color: #509EE2; }
    body section .btn-std:hover.btn-orange,
    .btn-std:hover.btn-orange {
      background: #fff;
      color: #333; }

/* Colors */
/* Menu */
.mainContainer > section {
  padding: 70px 0; }
  @media (max-width: 767px) {
    .mainContainer > section {
      padding: 35px 0; } }
  .mainContainer > section.--background {
    background-color: #F5F6F8;
    position: relative; }

@media (min-width: 1199px) {
  .--small-container {
    max-width: 950px !important; } }

.news__section {
  padding-bottom: 280px !important; }
  @media (max-width: 1199px) {
    .news__section {
      padding-bottom: 70px !important; } }
  @media (max-width: 767px) {
    .news__section {
      padding-bottom: 35px !important; } }

/* Colors */
/* Menu */
/* Colors */
/* Menu */
/* Colors */
/* Menu */
header#mainMenu {
  background-color: #fff;
  z-index: 990;
  height: 144px; }

@media (max-width: 990px) {
  header#mainMenu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    height: 128px; }
    header#mainMenu > .container {
      position: relative; }
  #mainMenu .header__wrapper {
    display: flex;
    padding: 8px 0;
    align-items: center; }
    #mainMenu .header__wrapper nav {
      position: absolute;
      top: 144px;
      background-color: #fff;
      width: 100%;
      left: 0;
      max-height: calc(100vh - 144px);
      padding-left: 10px;
      overflow: auto;
      padding-bottom: 0;
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1); }
      #mainMenu .header__wrapper nav ul {
        width: 100%;
        height: auto; }
        #mainMenu .header__wrapper nav ul li {
          position: relative;
          width: 100%;
          border-bottom: 1px solid #cbcbcb; }
          #mainMenu .header__wrapper nav ul li:last-child {
            border-bottom: none; }
          #mainMenu .header__wrapper nav ul li a {
            display: block;
            padding: 16px 6px;
            font-weight: 700;
            font-size: 1.6rem;
            color: #333;
            position: relative;
            display: flex;
            align-items: center;
            text-transform: uppercase; }
          #mainMenu .header__wrapper nav ul li.active > a,
          #mainMenu .header__wrapper nav ul li.child-active > a {
            color: #509EE2; }
          #mainMenu .header__wrapper nav ul li.w-submenu > a:after {
            content: "";
            width: 10px;
            display: block;
            height: 10px;
            border-bottom: 2px solid #333;
            border-right: 2px solid #333;
            transform: translateY(-5px) rotate(45deg);
            background-image: none;
            padding: 0;
            transition: transform 140ms ease-in;
            order: 3; }
          #mainMenu .header__wrapper nav ul li.w-submenu > a::before {
            content: "";
            display: block;
            width: 16px;
            height: 2px;
            margin-left: 12px;
            background-color: #333;
            background-image: none;
            padding: 0;
            opacity: 0;
            transform: translate(-10px, 0);
            transition: all 140ms ease-in;
            order: 2; }
          #mainMenu .header__wrapper nav ul li.w-submenu[data-status="clicked_once"] > a::after {
            transform: rotate(-45deg); }
          #mainMenu .header__wrapper nav ul li.w-submenu[data-status="clicked_once"] > a::before {
            opacity: 1;
            transform: translate(10px); }
        #mainMenu .header__wrapper nav ul li > ul {
          padding-left: 15px;
          border-left: 4px solid #333;
          display: none; }
          #mainMenu .header__wrapper nav ul li > ul li a {
            font-size: 1.4rem; }
          #mainMenu .header__wrapper nav ul li > ul > li > ul {
            padding-left: 15px;
            border-left: 4px solid #333; }
            #mainMenu .header__wrapper nav ul li > ul > li > ul li ul {
              background: red; }
  body header#mainMenu nav {
    display: none; }
  body.menuOpen {
    overflow: hidden;
    /*header#mainMenu {
			max-height: none;
			height: auto;
			overflow: auto;
		}*/ } }

/* Colors */
/* Menu */
.header__intro {
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid #333; }

.header__intro-item {
  margin-right: 10px;
  padding: 6px;
  position: relative; }
  .header__intro-item img {
    width: 20px;
    height: 20px; }
  .header__intro-item > span {
    position: absolute;
    top: 100%;
    right: 0;
    opacity: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 7px 10px;
    z-index: 10;
    pointer-events: none;
    color: #333;
    display: table;
    white-space: nowrap; }
  .header__intro-item:hover {
    background-color: #F5F6F8; }
    .header__intro-item:hover > span {
      background-color: #F5F6F8;
      opacity: 1; }

@media (min-width: 991px) {
  #mainMenu {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }
    #mainMenu li a {
      color: #333;
      line-height: 1.4; }
    #mainMenu .header__wrapper {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0; }
    #mainMenu nav {
      flex-grow: 1;
      display: flex; }
      #mainMenu nav ul {
        display: flex;
        margin: auto;
        margin-right: 0; }
        #mainMenu nav ul > li {
          position: relative; }
          #mainMenu nav ul > li.w-submenu > a::after {
            content: "";
            width: .7rem;
            height: .7rem;
            border-bottom: 2px solid;
            border-right: 2px solid;
            margin-left: 8px;
            transform: rotate(45deg) translate(-3px); }
          #mainMenu nav ul > li a {
            display: flex;
            align-items: center;
            padding: 5px 10px;
            font-weight: 700;
            color: inherit;
            transition: none;
            margin-right: 10px;
            border: 2px solid transparent;
            border-radius: 30px;
            transition: all 250ms ease-in-out; }
            #mainMenu nav ul > li a:hover {
              color: #509EE2; } }
        @media screen and (min-width: 991px) and (max-width: 1449px) {
          #mainMenu nav ul > li a {
            font-size: 1.7rem;
            padding: 5px 5px; } }
        @media screen and (min-width: 991px) and (max-width: 1199px) {
          #mainMenu nav ul > li a {
            font-size: 1.5rem;
            padding: 5px 0px;
            margin-right: 5px; } }

@media (min-width: 991px) {
        #mainMenu nav ul > li > ul {
          position: absolute;
          top: 100%;
          left: 0;
          min-width: 280px;
          width: auto;
          flex-direction: column;
          opacity: 0;
          pointer-events: none;
          height: auto;
          max-height: calc(100vh - 170px);
          border-radius: 15px;
          background-color: #509EE2;
          overflow: auto; }
          #mainMenu nav ul > li > ul:last-child {
            left: unset;
            right: 0; }
          #mainMenu nav ul > li > ul a {
            transition: padding-left 160ms ease-in; }
          #mainMenu nav ul > li > ul a:hover {
            padding-left: 26px; }
          #mainMenu nav ul > li > ul > li {
            border-radius: 0;
            color: #fff;
            position: relative;
            padding: 5px 0;
            border-top: 1px solid rgba(255, 255, 255, 0.35); }
            #mainMenu nav ul > li > ul > li a {
              display: block;
              border-radius: 0;
              color: #fff;
              padding-left: 14px;
              padding-right: 14px; }
              #mainMenu nav ul > li > ul > li a:hover {
                color: #fff;
                text-decoration: underline; }
            #mainMenu nav ul > li > ul > li:hover > a {
              color: #fff; }
            #mainMenu nav ul > li > ul > li.w-submenu > a:after {
              content: "";
              position: absolute;
              right: 10px;
              left: auto;
              top: 1.2em;
              width: 10px;
              height: 10px;
              border-bottom: 3px solid #fff;
              border-right: 3px solid #ffffff;
              transform: translate(0, -50%) rotate(45deg);
              background-image: none;
              padding: 0;
              transition: transform 140ms ease-in; }
            #mainMenu nav ul > li > ul > li.w-submenu > a::before {
              content: "";
              position: absolute;
              right: 18px;
              left: auto;
              top: 1.2em;
              width: 16px;
              height: 2px;
              background-color: #fff;
              background-image: none;
              padding: 0;
              opacity: 0;
              transform: translate(-10px, 0);
              transition: all 140ms ease-in; }
            #mainMenu nav ul > li > ul > li.w-submenu:hover > a:after {
              transform: rotate(45deg); }
            #mainMenu nav ul > li > ul > li.w-submenu[data-status="clicked_once"] > a::after {
              transform: translate(-10px, -50%) rotate(-45deg); }
            #mainMenu nav ul > li > ul > li.w-submenu[data-status="clicked_once"] > a::before {
              opacity: 1;
              transform: translate(-10px); }
            #mainMenu nav ul > li > ul > li.w-submenu[data-status="clicked_once"] a {
              background-color: #509EE2; }
              #mainMenu nav ul > li > ul > li.w-submenu[data-status="clicked_once"] a:hover:after {
                transform: translate(0, -50%) rotate(-45deg); }
              #mainMenu nav ul > li > ul > li.w-submenu[data-status="clicked_once"] a:hover:before {
                transform: translate(0); }
          #mainMenu nav ul > li > ul li > ul {
            position: relative;
            display: none;
            opacity: 1;
            background-color: transparent;
            border-left: 9px solid #fff;
            border-radius: 0; }
            #mainMenu nav ul > li > ul li > ul > li {
              width: 100%;
              color: #fff; }
              #mainMenu nav ul > li > ul li > ul > li a {
                position: relative;
                z-index: 10000;
                white-space: normal;
                color: #fff;
                padding-left: 20px; }
                #mainMenu nav ul > li > ul li > ul > li a:hover {
                  background-color: #000; }
            #mainMenu nav ul > li > ul li > ul li ul {
              display: none;
              border-left: 12px solid #fff; }
        #mainMenu nav ul li:hover > ul,
        #mainMenu nav ul li:focus > ul {
          opacity: 1;
          pointer-events: all; } }

#mainMenu .header__logo {
  width: auto;
  max-width: 100%;
  height: 75px; }
  #mainMenu .header__logo img {
    display: block;
    margin-bottom: -32px;
    background: #fff;
    border-radius: 50%;
    width: 100%;
    height: 107px;
    object-fit: contain;
    margin-right: 8px; }
  @media screen and (max-width: 1199px) {
    #mainMenu .header__logo {
      height: 55px; } }
  @media (max-width: 991px) {
    #mainMenu .header__logo {
      height: 65px; } }
  @media (max-width: 555px) {
    #mainMenu .header__logo img {
      margin-bottom: 0;
      height: 74px; } }
  #mainMenu .header__logo a:hover {
    text-decoration: none; }

#mainMenu a {
  display: flex;
  align-items: center;
  height: 100%; }

#mainMenu .header__logo-text {
  font-size: 1.5rem;
  flex-shrink: 0;
  line-height: 1.2;
  color: #509EE2;
  font-weight: 800; }
  #mainMenu .header__logo-text span {
    font-size: 1.3rem;
    color: #509EE2;
    font-weight: 400; }
  @media screen and (max-width: 1449px) {
    #mainMenu .header__logo-text {
      font-size: 1.4rem; } }
  @media screen and (max-width: 1199px) {
    #mainMenu .header__logo-text {
      font-size: 1.2rem; }
      #mainMenu .header__logo-text span {
        font-size: 1rem; } }

/* Colors */
/* Menu */
.header__wrapper {
  justify-content: space-between; }
  .header__wrapper label.header__hamburger {
    z-index: 9999;
    cursor: pointer;
    width: 40px;
    height: 50px;
    margin-right: 0;
    margin-left: 0;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    order: 10; }
    .header__wrapper label.header__hamburger input {
      display: none; }
    .header__wrapper label.header__hamburger span {
      display: inline-block;
      width: 30px;
      height: 4px;
      border-radius: 3px;
      background: #333;
      margin: 3px 0 3px auto;
      transition: all 200ms ease-in-out; }
      .header__wrapper label.header__hamburger span:nth-child(3) {
        width: 25px; }
    .header__wrapper label.header__hamburger:hover span:nth-child(3) {
      width: 30px; }

@media (max-width: 991px) {
  .header__wrapper label.header__hamburger {
    display: flex;
    transform-origin: center center; }
  body.menuOpen header#mainMenu nav {
    opacity: 1;
    display: block; }
  body.menuOpen .header__wrapper label.header__hamburger {
    display: flex; }
    body.menuOpen .header__wrapper label.header__hamburger span {
      background-color: #333;
      transform-origin: center center; }
    body.menuOpen .header__wrapper label.header__hamburger span:nth-child(3) {
      width: 0;
      opacity: 0; }
    body.menuOpen .header__wrapper label.header__hamburger span:nth-child(2) {
      transform: translate(0, 10px) rotate(45deg); }
    body.menuOpen .header__wrapper label.header__hamburger span:nth-child(4) {
      transform: translate(0, -10px) rotate(-45deg); }
  .header__wrapper nav ul li ul {
    opacity: 1; }
  .header__wrapper nav ul li:hover > ul,
  .header__wrapper nav ul li:focus > ul {
    display: block;
    opacity: 1;
    z-index: 100; } }

@media (max-width: 991px) {
  .header__wrapper label.header__hamburger {
    display: flex;
    transform-origin: center center; }
  body.menuOpen header#mainMenu nav {
    opacity: 1;
    display: block; }
  body.menuOpen .header__wrapper label.header__hamburger {
    display: flex; }
    body.menuOpen .header__wrapper label.header__hamburger span {
      background-color: #333;
      transform-origin: center center; }
    body.menuOpen .header__wrapper label.header__hamburger span:nth-child(3) {
      width: 0;
      opacity: 0; }
    body.menuOpen .header__wrapper label.header__hamburger span:nth-child(2) {
      transform: translate(0, 10px) rotate(45deg); }
    body.menuOpen .header__wrapper label.header__hamburger span:nth-child(4) {
      transform: translate(0, -10px) rotate(-45deg); }
  .header__wrapper nav ul li ul {
    opacity: 1; }
  .header__wrapper nav ul li:hover > ul,
  .header__wrapper nav ul li:focus > ul {
    display: block;
    opacity: 1;
    z-index: 100; } }

header#mainMenu.scrolled {
  background-color: #fff;
  box-shadow: 0px 6px 21px -9px rgba(0, 0, 0, 0.25);
  position: fixed;
  animation: slide-down 500ms;
  height: 99px; }
  header#mainMenu.scrolled .header__intro {
    display: none; }
  @media (max-width: 991px) {
    header#mainMenu.scrolled {
      height: 82px; } }

@keyframes slide-down {
  0% {
    transform: translate(0, -100%); }
  100% {
    transform: translate(0, 0%); } }

body {
  padding-top: 144px; }

/* Colors */
/* Menu */
.footer {
  background: #509EE2;
  padding: 30px 0 30px 0; }
  @media (max-width: 991px) {
    .footer {
      padding-bottom: 30px;
      padding: 30px 0 10px 0; } }

.footer__wrapper {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
  margin-bottom: 32px; }

.footer__col a, .footer__col div {
  color: #fff; }

.footer__col.--logo {
  margin-right: 64px; }

.footer__col.--contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 40px; }

.footer__col.--socials {
  display: flex;
  justify-content: center;
  flex-grow: 1; }

@media (max-width: 991px) {
  .footer__col {
    width: 100%; }
    .footer__col.--logo, .footer__col.--contact {
      margin-right: 0;
      justify-content: center;
      align-items: center;
      margin-bottom: 36px; }
    .footer__col.--socials {
      margin-bottom: 16px; } }

.footer__logo {
  margin-bottom: 16px; }
  @media (max-width: 991px) {
    .footer__logo {
      display: block;
      margin: auto;
      margin-bottom: 16px; } }

.footer__logo-text {
  color: #fff;
  font-weight: 700;
  line-height: 1.4;
  font-size: 1.5rem; }
  .footer__logo-text span {
    font-weight: 400;
    color: #fff;
    font-size: 1.3rem; }
  @media (max-width: 991px) {
    .footer__logo-text {
      text-align: center; } }

.footer__phone, .footer__email {
  display: flex;
  align-items: center;
  font-size: 2.4rem;
  font-weight: 700; }
  .footer__phone::before, .footer__email::before {
    display: block;
    content: "";
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-right: 12px; }
  @media (max-width: 767px) {
    .footer__phone, .footer__email {
      font-size: 2rem; } }

.footer__phone {
  margin-bottom: 10px; }
  .footer__phone::before {
    background-image: url("../img/phone.svg");
    width: 24px;
    height: 32px; }

.footer__email::before {
  background-image: url("../img/email.svg");
  width: 24px;
  height: 20px; }

.footer-copyright {
  background-color: #509EE2; }

.footer-copyright__content {
  text-align: center;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-top: 1px solid #fff;
  padding: 20px 0; }

.footer-copyright__content-item {
  color: #fff; }
  .footer-copyright__content-item a {
    margin-right: 15px;
    color: #fff; }
  @media (max-width: 991px) {
    .footer-copyright__content-item {
      display: flex;
      flex-direction: column;
      width: 100%; }
      .footer-copyright__content-item:last-child {
        order: -1;
        margin-bottom: 20px; }
      .footer-copyright__content-item a {
        margin-right: 0;
        margin-bottom: 10px; } }

.footer__menu-wrapper {
  display: flex;
  flex-wrap: wrap; }
  .footer__menu-wrapper div, .footer__menu-wrapper a {
    color: #fff;
    margin-right: 16px;
    font-size: 2rem; }
  .footer__menu-wrapper ul li {
    margin-bottom: 8px; }
  @media (max-width: 991px) {
    .footer__menu-wrapper {
      width: 100%;
      text-align: center;
      flex-direction: column; }
      .footer__menu-wrapper div, .footer__menu-wrapper a {
        margin-right: 0;
        margin-bottom: 16px; } }

.footer__contact-item {
  display: flex; }

.footer__socials {
  display: block;
  margin-right: 16px; }
  .footer__socials img {
    width: 30px;
    height: 30px; }

/* Colors */
/* Menu */
.static-page {
  background-color: #fff;
  padding: 70px 0; }
  @media (max-width: 767px) {
    .static-page {
      padding: 35px 0; } }

.dynamic-page__wrapper {
  display: flex; }
  .dynamic-page__wrapper strong {
    font-weight: 700 !important; }
  .dynamic-page__wrapper .dynamic-page__content {
    max-width: 100%;
    flex-grow: 1; }
    @media screen and (min-width: 991px) {
      .dynamic-page__wrapper .dynamic-page__content {
        max-width: calc(100% - 320px); } }
    .dynamic-page__wrapper .dynamic-page__content h1 {
      font-size: 6rem;
      font-weight: 700;
      margin-bottom: 30px;
      text-align: left;
      position: relative;
      width: 100%; }
      .dynamic-page__wrapper .dynamic-page__content h1.text-center {
        text-align: center; }
      @media screen and (max-width: 767px) {
        .dynamic-page__wrapper .dynamic-page__content h1 {
          font-size: 4rem;
          margin-bottom: 20px; } }
    .dynamic-page__wrapper .dynamic-page__content h2 {
      font-family: 'Montserrat', sans-serif;
      font-size: 4.4rem;
      margin-bottom: 22px;
      font-weight: 400;
      line-height: 1.1;
      text-align: left;
      width: 100%;
      position: relative;
      padding-bottom: 10px;
      font-weight: 700; }
      .dynamic-page__wrapper .dynamic-page__content h2.text-center {
        text-align: center; }
      @media screen and (max-width: 767px) {
        .dynamic-page__wrapper .dynamic-page__content h2 {
          font-size: 3rem;
          margin-bottom: 15px; } }
    .dynamic-page__wrapper .dynamic-page__content h3 {
      font-size: 3.2rem;
      position: relative;
      font-weight: 700;
      margin-bottom: 10px;
      line-height: 1.4;
      text-align: left;
      width: 100%; }
      .dynamic-page__wrapper .dynamic-page__content h3.text-center {
        text-align: center; }
      @media screen and (max-width: 767px) {
        .dynamic-page__wrapper .dynamic-page__content h3 {
          font-size: 2.2rem;
          margin-bottom: 8px;
          line-height: 1.1; } }
    .dynamic-page__wrapper .dynamic-page__content h4 {
      font-size: 2.2rem;
      position: relative;
      font-weight: 700;
      margin-bottom: 8px;
      line-height: 1.4; }
      @media screen and (max-width: 767px) {
        .dynamic-page__wrapper .dynamic-page__content h4 {
          font-size: 1.8rem;
          margin-bottom: 5px;
          line-height: 1.1; } }
    .dynamic-page__wrapper .dynamic-page__content hr {
      color: #999999;
      margin: 30px auto;
      display: block; }
    .dynamic-page__wrapper .dynamic-page__content p {
      font-size: 1.8rem;
      line-height: 1.6;
      margin-bottom: 1.5rem; }
      @media screen and (max-width: 767px) {
        .dynamic-page__wrapper .dynamic-page__content p {
          font-size: 1.6rem;
          line-height: 1.6;
          margin-bottom: 20px; } }
    .dynamic-page__wrapper .dynamic-page__content a {
      text-decoration: underline; }
  .dynamic-page__wrapper table {
    display: block;
    width: 100%;
    overflow-x: auto;
    margin: 0 0;
    margin-bottom: 40px; }
    .dynamic-page__wrapper table thead {
      text-align: left;
      font-size: 1.6rem; }
      .dynamic-page__wrapper table thead tr {
        background-color: #509EE2; }
      .dynamic-page__wrapper table thead th {
        border: 1px solid #509EE2;
        color: #fff;
        font-weight: 700;
        padding: 10px;
        padding-left: 25px; }
    .dynamic-page__wrapper table tbody tr {
      background: #fff; }
    .dynamic-page__wrapper table tbody tr:nth-child(odd) {
      background-color: #efefef; }
    .dynamic-page__wrapper table td {
      border: 1px solid #509EE2;
      padding: 10px 10px; }
      .dynamic-page__wrapper table td a {
        font-weight: 700;
        white-space: nowrap; }
      .dynamic-page__wrapper table td p {
        margin-bottom: 0 !important; }
  .dynamic-page__wrapper ol,
  .dynamic-page__wrapper ul {
    margin-bottom: 20px; }
    .dynamic-page__wrapper ol li,
    .dynamic-page__wrapper ul li {
      line-height: 1.6;
      margin-bottom: 5px; }
  .dynamic-page__wrapper ul li {
    position: relative;
    padding-left: 15px; }
    .dynamic-page__wrapper ul li::marker {
      display: none; }
    .dynamic-page__wrapper ul li::before {
      position: absolute;
      content: "";
      width: 6px;
      height: 6px;
      top: .6em;
      left: 0;
      background-color: #509EE2;
      border-radius: 50%; }
    .dynamic-page__wrapper ul li ul {
      margin-bottom: 0; }
    .dynamic-page__wrapper ul li ul > li {
      position: relative;
      padding-left: 15px; }
      .dynamic-page__wrapper ul li ul > li::before {
        position: absolute;
        content: "";
        width: 6px;
        height: 6px;
        top: .6em;
        left: 0;
        background-color: #000;
        border-radius: 50%; }
  .dynamic-page__wrapper ol {
    list-style-type: revert; }
    .dynamic-page__wrapper ol li {
      margin-left: 20px; }
  @media (max-width: 990px) {
    .dynamic-page__wrapper {
      flex-wrap: wrap; } }
  .dynamic-page__wrapper a {
    color: #509EE2; }

a {
  transition: all 250ms ease-in-out; }
  a:hover {
    text-decoration: underline; }

.table-responsive {
  display: block;
  overflow: auto;
  width: 100%;
  max-width: 100%; }
  .table-responsive .table {
    width: 100%; }

/* Colors */
/* Menu */
.static-page__submenu {
  width: 310px;
  max-width: 100%;
  flex-shrink: 0;
  padding-right: 30px; }
  .static-page__submenu > ul {
    width: 100%;
    background-color: #509EE2;
    color: #fff;
    border-radius: 10px; }
    .static-page__submenu > ul li {
      padding: 0;
      color: #fff;
      padding-right: 15px;
      background-color: #509EE2;
      border-radius: 10px;
      margin-bottom: 0; }
      .static-page__submenu > ul li:before {
        display: none; }
    .static-page__submenu > ul li a {
      color: inherit;
      transition: all 250ms ease-in-out; }
      .static-page__submenu > ul li a:hover {
        text-decoration: underline; }
    .static-page__submenu > ul > li {
      border-collapse: collapse;
      color: inherit; }
      .static-page__submenu > ul > li:last-child {
        border-bottom: unset; }
      .static-page__submenu > ul > li > a {
        display: block;
        padding: 15px;
        margin-bottom: 0;
        font-weight: 700;
        font-size: 2rem;
        color: inherit; }
      .static-page__submenu > ul > li ul {
        display: none;
        padding-bottom: 10px; }
        .static-page__submenu > ul > li ul li {
          padding-left: 30px; }
          .static-page__submenu > ul > li ul li.child-active > a, .static-page__submenu > ul > li ul li.active > a {
            font-weight: 700;
            color: #333; }
          .static-page__submenu > ul > li ul li.child-active > ul,
          .static-page__submenu > ul > li ul li.child-active.child-active > ul, .static-page__submenu > ul > li ul li.active > ul,
          .static-page__submenu > ul > li ul li.active.child-active > ul {
            display: block; }
          .static-page__submenu > ul > li ul li ul {
            margin-top: 0;
            padding-bottom: 0; }
            .static-page__submenu > ul > li ul li ul li {
              padding-left: 10px; }
      .static-page__submenu > ul > li.active > ul,
      .static-page__submenu > ul > li.child-active > ul {
        display: block; }
  @media (max-width: 990px) {
    .static-page__submenu {
      display: none;
      width: 100%;
      padding-right: 0; } }

#static-menu-mobile-trigger {
  display: none; }
  @media (max-width: 990px) {
    #static-menu-mobile-trigger {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 0 20px;
      background: #509EE2;
      width: 100%;
      border-radius: 10px;
      margin: 0 0 22px;
      font-weight: 700;
      color: #fff;
      cursor: pointer;
      position: relative;
      overflow: hidden; }
      #static-menu-mobile-trigger:after {
        content: "";
        display: block;
        width: 48px;
        height: 48px;
        background-image: url("../img/submenu-down-white.svg");
        background-size: 50% auto;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #333; }
      #static-menu-mobile-trigger:hover {
        background-color: #fff;
        box-shadow: inset 0 0 0 2px #509EE2;
        color: #509EE2 !important; }
        #static-menu-mobile-trigger:hover:after {
          background-color: #509EE2;
          transition: background-color 200ms ease-in-out, color 200ms ease-in-out !important; } }

/* Colors */
/* Menu */
/* Colors */
/* Menu */
/* Colors */
/* Menu */
.slick__arrow {
  position: absolute;
  left: 10px;
  top: calc(50% - 42px);
  width: 20px;
  height: 36px;
  background-image: url(../img/arrow.svg);
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 10;
  border: none;
  outline: none;
  transition: all 250ms ease-in-out;
  cursor: pointer; }
  .slick__arrow.slick__arrow-next {
    background-image: url(../img/arrow.svg);
    left: unset;
    right: 10px;
    transform: rotate(180deg); }

.partners__slick {
  position: relative; }

.partners__logos {
  width: 90%;
  margin: auto;
  margin-bottom: 30px; }

.slick-dots {
  display: flex;
  justify-content: center;
  align-items: center; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 19px;
    background-color: transparent;
    border-radius: 50%;
    border: 3px solid #509EE2;
    margin-bottom: 0 !important;
    flex-shrink: 0;
    transition: background-color 200ms ease-in-out;
    cursor: pointer;
    margin: 0 2px; }
    .slick-dots li.slick-active {
      border: 3px solid #509EE2;
      background-color: #509EE2;
      height: 20px;
      width: 19px; }
    .slick-dots li:before {
      display: none; }
  .slick-dots li button {
    display: none; }

/* Colors */
/* Menu */
.cookies-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f1f1f1;
  z-index: 1000;
  padding: 15px 0; }
  .cookies-wrapper .container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 40px; }
    .cookies-wrapper .container p {
      font-size: 1.8rem;
      margin-right: 10px;
      margin-bottom: 0;
      opacity: 0.6; }
      .cookies-wrapper .container p a {
        color: #333;
        text-decoration: underline; }

@media (max-width: 991px) {
  .cookies-wrapper .container {
    flex-wrap: wrap;
    text-align: center; }
    .cookies-wrapper .container p {
      margin-right: 0px;
      margin-bottom: 10px; }
    .cookies-wrapper .container a.cookie-close {
      margin: auto; } }

/* Colors */
/* Menu */
.files__wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  margin-bottom: 50px; }

.files__item {
  width: calc(50% - 10px);
  margin-right: 10px;
  border: 2px solid #509EE2;
  padding: 15px;
  background-color: #fff;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  transition: all 250ms ease-in-out;
  position: relative;
  color: #333;
  border-radius: 10px; }
  .files__item a,
  .files__item div {
    color: #333;
    text-decoration: none !important; }
  .files__item:hover {
    transform: translate(0, -5px); }
  @media (max-width: 991px) {
    .files__item {
      width: 100%;
      margin-right: 0; } }

.files__item-file-type {
  width: 100%;
  position: relative;
  padding: 0 0 0 50px;
  font-weight: 700;
  word-break: break-word;
  line-height: 1.2; }
  .files__item-file-type::before {
    position: absolute;
    content: "";
    top: 50%;
    left: 0;
    width: 30px;
    height: 30px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    transform: translate(0, -50%); }

a[href$=".pdf"]::before {
  background-image: url("../img/pdf.svg"); }

a[href$=".doc"]::before,
a[href$=".docx"]::before {
  background-image: url("../img/word.svg"); }

a[href$=".xlsx"]::before,
a[href$=".xls"]::before {
  background-image: url("../img/excel.svg"); }

/* Colors */
/* Menu */
.about-us__history {
  overflow: hidden;
  position: relative;
  padding-left: 25px; }
  .about-us__history:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 250px;
    right: 0;
    top: 0;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.15) 0%, white 100%);
    z-index: 2; }
  @media (max-width: 991px) {
    .about-us__history {
      padding-left: 0; }
      .about-us__history:before {
        display: none; } }

.about-us__wrapper {
  display: flex;
  position: relative;
  align-items: center;
  z-index: 2;
  margin-bottom: 70px;
  flex-wrap: wrap; }
  @media (max-width: 767px) {
    .about-us__wrapper {
      margin-bottom: 30px; } }

.about-us__content {
  width: calc(45% - 70px);
  margin-right: 70px; }
  @media (max-width: 1199px) {
    .about-us__content {
      width: calc(55% - 70px); } }
  @media (max-width: 991px) {
    .about-us__content {
      margin-right: 0;
      width: 100%; } }

.about-us__heading {
  text-align: left;
  font-size: 6.4rem; }
  @media (max-width: 1199px) {
    .about-us__heading {
      font-size: 5rem; } }
  @media (max-width: 991px) {
    .about-us__heading {
      font-size: 4.4rem;
      margin-bottom: 30px; } }
  @media (max-width: 767px) {
    .about-us__heading {
      font-size: 2.8rem; } }

.about-us__image {
  width: 55%; }
  .about-us__image img {
    width: 100%;
    height: 650px;
    object-fit: contain; }
  @media (max-width: 1199px) {
    .about-us__image {
      width: 45%; } }
  @media (max-width: 991px) {
    .about-us__image {
      width: 100%;
      margin-bottom: 30px; }
      .about-us__image img {
        height: 350px;
        display: block;
        margin: auto; } }
  @media (max-width: 555px) {
    .about-us__image img {
      height: 250px; } }

.about-us {
  padding-top: 280px !important; }
  @media (max-width: 1199px) {
    .about-us {
      padding-top: 70px !important; } }
  @media (max-width: 767px) {
    .about-us {
      padding-bottom: 35px !important; } }

/* Colors */
/* Menu */
#mainMenu .search-button {
  display: block;
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  margin: auto 10px;
  transition: all 200ms ease-in-out;
  border-radius: 4px;
  position: relative;
  transition: background 220ms ease-in-out; }
  #mainMenu .search-button:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../img/search-icon.svg");
    background-size: 2.8rem auto;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 220ms ease-in-out; }
  #mainMenu .search-button:hover {
    background-color: #509EE2; }
    #mainMenu .search-button:hover:before {
      filter: brightness(0) invert(1);
      transform: scale(0.8); }

.ss-result {
  padding: 10px;
  margin: 20px 0 40px; }
  .ss-result h4 {
    font-size: 24px;
    display: inline-block;
    margin-bottom: 14px;
    position: relative;
    font-weight: 700; }
    .ss-result h4:after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background-color: #509EE2;
      position: absolute;
      left: 0;
      bottom: -4px; }
    .ss-result h4 a {
      color: #333; }
      .ss-result h4 a:hover {
        text-decoration: none; }
  .ss-result mark {
    font-weight: 600;
    display: inline-block;
    padding: 2px 6px;
    border-radius: 6px; }
  .ss-result p {
    margin-bottom: 8px; }
  .ss-result .ss-result__badge {
    display: inline-block;
    font-size: 11px;
    padding: 6px 11px;
    background-color: #509EE2;
    color: #fff;
    margin-left: 10px;
    border-radius: 8px;
    vertical-align: top; }
    .ss-result .ss-result__badge.badge-red {
      background-color: #509EE2; }
    .ss-result .ss-result__badge.badge-blue {
      background-color: #6bc537; }
  .ss-result .ss-result__link {
    font-weight: 600;
    position: relative;
    color: #333;
    text-decoration: none; }
    .ss-result .ss-result__link:after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background-color: #509EE2;
      position: absolute;
      left: 0;
      bottom: -4px; }
    .ss-result .ss-result__link:hover {
      text-decoration: none; }

.search-modal {
  position: fixed;
  width: 100%;
  height: 0;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 1080;
  background: #eee;
  justify-content: center;
  align-items: center;
  display: none;
  transition: all 250ms ease-in-out; }
  .search-modal.shown {
    display: flex;
    animation: toFullHeight 300ms ease-in-out forwards; }
  .search-modal input {
    background-color: #fff;
    font-size: 38px;
    font-weight: 700;
    padding: 30px 40px;
    border: none;
    border-radius: 60px;
    margin-bottom: 20px; }
  .search-modal button.ss-search-form__submit {
    font-size: 22px;
    margin: 20px;
    border: none; }
  .search-modal .close-modal.close-modal-btn {
    font-size: 22px;
    font-weight: 700;
    display: inline-block;
    color: #333;
    margin: 10px; }
  .search-modal .close-modal.close-modal-cross {
    position: absolute;
    width: 80px;
    height: 80px !important;
    display: block;
    border: 3px solid #757575;
    top: 30px;
    right: 30px;
    border-radius: 50%; }
    .search-modal .close-modal.close-modal-cross:before, .search-modal .close-modal.close-modal-cross:after {
      content: "";
      position: absolute;
      width: 60%;
      height: 60%;
      top: 50%;
      left: 50%;
      height: 3px;
      display: block;
      background-color: #757575;
      transform: translate(-50%, -50%) rotate(45deg); }
    .search-modal .close-modal.close-modal-cross:after {
      transform: translate(-50%, -50%) rotate(-45deg); }
  .search-modal .ss-search-form__results.ss-search-form__results--visible {
    padding: 30px 30px 10px;
    background: #fff;
    border-radius: 30px; }
    .search-modal .ss-search-form__results.ss-search-form__results--visible .ss-autocomplete-result {
      font-size: 2.2rem;
      font-weight: 700;
      text-decoration: underline; }
      .search-modal .ss-search-form__results.ss-search-form__results--visible .ss-autocomplete-result a {
        color: #333; }
      .search-modal .ss-search-form__results.ss-search-form__results--visible .ss-autocomplete-result .ss-autocomplete-result__badge {
        display: inline-block;
        background-color: #509EE2;
        font-size: 1.2rem;
        color: #fff;
        padding: 4px 12px;
        border-radius: 6px; }

@keyframes toFullHeight {
  0% {
    height: 0; }
  100% {
    height: 100%; } }

@media (max-width: 767px) {
  .search-modal input {
    display: table;
    width: 100%;
    max-width: 100%;
    font-size: 24px;
    padding: 20px 25px; }
  .search-modal .close-modal.close-modal-cross {
    width: 50px;
    height: 50px; } }

@media (max-width: 555px) {
  .search-modal input {
    width: 100%;
    font-size: 18px; } }

@media (max-width: 990px) {
  #mainMenu .search-button {
    margin-left: auto; } }

.pagination {
  display: flex;
  justify-content: center; }
  .pagination li:before {
    display: none; }

/**
 * Lightcase - jQuery Plugin
 * The smart and flexible Lightbox Plugin.
 *
 * @author		Cornel Boppart <cornel@bopp-art.com>
 * @copyright	Author
 *
 * @version		2.5.0 (11/03/2018)
 */
/**
 * Lightcase settings
 *
 * Note: Override default settings for your project without touching this source code by simply
 * defining those variables within a SASS map called '$lightcase-custom'.
 *
 * // Example usage
 * $lightcase-custom: (
 *   'breakpoint': 768px
 * );
 */
@font-face {
  font-family: 'lightcase';
  src: url("../../lightcase/lightcase.eot?55356177");
  src: url("../../lightcase/lightcase.eot?55356177#iefix") format("embedded-opentype"), url("../../lightcase/lightcase.woff?55356177") format("woff"), url("../../lightcase/lightcase.ttf?55356177") format("truetype"), url("../../lightcase/lightcase.svg?55356177#lightcase") format("svg");
  font-weight: normal;
  font-style: normal; }

[class*='lightcase-icon-']:before {
  font-family: 'lightcase', sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

/* Codes */
.lightcase-icon-play:before {
  content: '\e800'; }

.lightcase-icon-pause:before {
  content: '\e801'; }

.lightcase-icon-close:before {
  content: '\e802'; }

.lightcase-icon-prev:before {
  content: '\e803'; }

.lightcase-icon-next:before {
  content: '\e804'; }

.lightcase-icon-spin:before {
  content: '\e805'; }

/**
 * Mixin providing icon defaults to be used on top of font-lightcase.
 *
 * Example usage:
 * @include icon(#e9e9e9)
 */
/**
 * Mixin providing icon defaults including a hover status to be used
 * on top of font-lightcase.
 *
 * Example usage:
 * @include icon-hover(#e9e9e9, #fff)
 */
/**
 * Provides natural content overflow behavior and scrolling support
 * even so for touch devices.
 *
 * Example usage:
 * @include overflow()
 */
/**
 * Neutralizes/resets dimensions including width, height, position as well as margins,
 * paddings and styles. Used to enforce a neutral and unstyled look and behavoir!
 *
 * Example usage:
 * @include clear(true)
 *
 * @param boolean $important
 */
@-webkit-keyframes lightcase-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg); } }

@-moz-keyframes lightcase-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg); } }

@-o-keyframes lightcase-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg); } }

@-ms-keyframes lightcase-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes lightcase-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg); } }

#lightcase-case {
  display: none;
  position: fixed;
  z-index: 2002;
  top: 50%;
  left: 50%;
  font-family: arial, sans-serif;
  font-size: 13px;
  line-height: 1.5;
  text-align: left;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5); }
  @media screen and (max-width: 640px) {
    html[data-lc-type=inline] #lightcase-case,
    html[data-lc-type=ajax] #lightcase-case {
      position: fixed !important;
      top: 0 !important;
      left: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      margin: 0 !important;
      padding: 55px 0 70px 0;
      width: 100% !important;
      height: 100% !important;
      overflow: auto !important; } }

@media screen and (min-width: 641px) {
  html:not([data-lc-type=error]) #lightcase-content {
    position: relative;
    z-index: 1;
    text-shadow: none;
    background-color: #fff;
    -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
    -o-box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
    -webkit-backface-visibility: hidden; } }

@media screen and (min-width: 641px) {
  html[data-lc-type=image] #lightcase-content,
  html[data-lc-type=video] #lightcase-content {
    background-color: #333; } }

html[data-lc-type=inline] #lightcase-content,
html[data-lc-type=ajax] #lightcase-content,
html[data-lc-type=error] #lightcase-content {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none; }
  @media screen and (max-width: 640px) {
    html[data-lc-type=inline] #lightcase-content,
    html[data-lc-type=ajax] #lightcase-content,
    html[data-lc-type=error] #lightcase-content {
      position: relative !important;
      top: auto !important;
      left: auto !important;
      width: auto !important;
      height: auto !important;
      margin: 0 !important;
      padding: 0 !important;
      border: none !important;
      background: none !important; } }
  html[data-lc-type=inline] #lightcase-content .lightcase-contentInner,
  html[data-lc-type=ajax] #lightcase-content .lightcase-contentInner,
  html[data-lc-type=error] #lightcase-content .lightcase-contentInner {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box; }
    @media screen and (max-width: 640px) {
      html[data-lc-type=inline] #lightcase-content .lightcase-contentInner,
      html[data-lc-type=ajax] #lightcase-content .lightcase-contentInner,
      html[data-lc-type=error] #lightcase-content .lightcase-contentInner {
        padding: 15px; }
        html[data-lc-type=inline] #lightcase-content .lightcase-contentInner, html[data-lc-type=inline] #lightcase-content .lightcase-contentInner > *,
        html[data-lc-type=ajax] #lightcase-content .lightcase-contentInner,
        html[data-lc-type=ajax] #lightcase-content .lightcase-contentInner > *,
        html[data-lc-type=error] #lightcase-content .lightcase-contentInner,
        html[data-lc-type=error] #lightcase-content .lightcase-contentInner > * {
          width: 100% !important;
          max-width: none !important; }
        html[data-lc-type=inline] #lightcase-content .lightcase-contentInner > *:not(iframe),
        html[data-lc-type=ajax] #lightcase-content .lightcase-contentInner > *:not(iframe),
        html[data-lc-type=error] #lightcase-content .lightcase-contentInner > *:not(iframe) {
          height: auto !important;
          max-height: none !important; } }

@media screen and (max-width: 640px) {
  html.lightcase-isMobileDevice[data-lc-type=iframe] #lightcase-content .lightcase-contentInner iframe {
    overflow: auto;
    -webkit-overflow-scrolling: touch; } }

@media screen and (max-width: 640px) and (min-width: 641px) {
  html[data-lc-type=image] #lightcase-content .lightcase-contentInner,
  html[data-lc-type=video] #lightcase-content .lightcase-contentInner {
    line-height: 0.75; } }

html[data-lc-type=image] #lightcase-content .lightcase-contentInner {
  position: relative;
  overflow: hidden !important; }

@media screen and (max-width: 640px) {
  html[data-lc-type=inline] #lightcase-content .lightcase-contentInner .lightcase-inlineWrap,
  html[data-lc-type=ajax] #lightcase-content .lightcase-contentInner .lightcase-inlineWrap,
  html[data-lc-type=error] #lightcase-content .lightcase-contentInner .lightcase-inlineWrap {
    position: relative !important;
    top: auto !important;
    left: auto !important;
    width: auto !important;
    height: auto !important;
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
    background: none !important; } }

@media screen and (min-width: 641px) {
  html:not([data-lc-type=error]) #lightcase-content .lightcase-contentInner .lightcase-inlineWrap {
    padding: 30px;
    overflow: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box; } }

@media screen and (max-width: 640px) {
  #lightcase-content h1, #lightcase-content h2, #lightcase-content h3, #lightcase-content h4, #lightcase-content h5, #lightcase-content h6, #lightcase-content p {
    color: #aaa; } }

@media screen and (min-width: 641px) {
  #lightcase-content h1, #lightcase-content h2, #lightcase-content h3, #lightcase-content h4, #lightcase-content h5, #lightcase-content h6, #lightcase-content p {
    color: #333; } }

#lightcase-case p.lightcase-error {
  margin: 0;
  font-size: 17px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #aaa; }
  @media screen and (max-width: 640px) {
    #lightcase-case p.lightcase-error {
      padding: 30px 0; } }
  @media screen and (min-width: 641px) {
    #lightcase-case p.lightcase-error {
      padding: 0; } }

.lightcase-open body {
  overflow: hidden; }
  .lightcase-isMobileDevice .lightcase-open body {
    max-width: 100%;
    max-height: 100%; }

#lightcase-info {
  position: absolute;
  padding-top: 15px; }
  #lightcase-info #lightcase-title,
  #lightcase-info #lightcase-caption {
    margin: 0;
    padding: 0;
    line-height: 1.5;
    font-weight: normal;
    text-overflow: ellipsis; }
  #lightcase-info #lightcase-title {
    font-size: 17px;
    color: #aaa; }
    @media screen and (max-width: 640px) {
      #lightcase-info #lightcase-title {
        position: fixed;
        top: 10px;
        left: 0;
        max-width: 87.5%;
        padding: 5px 15px;
        background: #333; } }
  #lightcase-info #lightcase-caption {
    clear: both;
    font-size: 13px;
    color: #aaa; }
  #lightcase-info #lightcase-sequenceInfo {
    font-size: 11px;
    color: #aaa; }
  @media screen and (max-width: 640px) {
    .lightcase-fullScreenMode #lightcase-info {
      padding-left: 15px;
      padding-right: 15px; }
    html:not([data-lc-type=image]):not([data-lc-type=video]):not([data-lc-type=flash]):not([data-lc-type=error]) #lightcase-info {
      position: static; } }

#lightcase-loading {
  position: fixed;
  z-index: 9999;
  width: 1.123em;
  height: auto;
  font-size: 38px;
  line-height: 1;
  text-align: center;
  text-shadow: none;
  position: fixed;
  z-index: 2001;
  top: 50%;
  left: 50%;
  margin-top: -0.5em;
  margin-left: -0.5em;
  opacity: 1;
  font-size: 32px;
  text-shadow: 0 0 15px #fff;
  -moz-transform-origin: 50% 53%;
  -webkit-animation: lightcase-spin 0.5s infinite linear;
  -moz-animation: lightcase-spin 0.5s infinite linear;
  -o-animation: lightcase-spin 0.5s infinite linear;
  animation: lightcase-spin 0.5s infinite linear; }
  #lightcase-loading, #lightcase-loading:focus {
    text-decoration: none;
    color: #fff;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-transition: color, opacity, ease-in-out 0.25s;
    -moz-transition: color, opacity, ease-in-out 0.25s;
    -o-transition: color, opacity, ease-in-out 0.25s;
    transition: color, opacity, ease-in-out 0.25s; }
  #lightcase-loading > span {
    display: inline-block;
    text-indent: -9999px; }

a[class*='lightcase-icon-'] {
  position: fixed;
  z-index: 9999;
  width: 1.123em;
  height: auto;
  font-size: 38px;
  line-height: 1;
  text-align: center;
  text-shadow: none;
  outline: none;
  cursor: pointer; }
  a[class*='lightcase-icon-'], a[class*='lightcase-icon-']:focus {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.6);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-transition: color, opacity, ease-in-out 0.25s;
    -moz-transition: color, opacity, ease-in-out 0.25s;
    -o-transition: color, opacity, ease-in-out 0.25s;
    transition: color, opacity, ease-in-out 0.25s; }
  a[class*='lightcase-icon-'] > span {
    display: inline-block;
    text-indent: -9999px; }
  a[class*='lightcase-icon-']:hover {
    color: white;
    text-shadow: 0 0 15px white; }
  .lightcase-isMobileDevice a[class*='lightcase-icon-']:hover {
    color: #aaa;
    text-shadow: none; }
  a[class*='lightcase-icon-'].lightcase-icon-close {
    position: fixed;
    top: 15px;
    right: 15px;
    bottom: auto;
    margin: 0;
    opacity: 0;
    outline: none; }
  a[class*='lightcase-icon-'].lightcase-icon-prev {
    left: 15px; }
  a[class*='lightcase-icon-'].lightcase-icon-next {
    right: 15px; }
  a[class*='lightcase-icon-'].lightcase-icon-pause, a[class*='lightcase-icon-'].lightcase-icon-play {
    left: 50%;
    margin-left: -0.5em; }
    @media screen and (min-width: 641px) {
      a[class*='lightcase-icon-'].lightcase-icon-pause, a[class*='lightcase-icon-'].lightcase-icon-play {
        opacity: 0; } }
  @media screen and (max-width: 640px) {
    a[class*='lightcase-icon-'] {
      bottom: 15px;
      font-size: 24px; } }
  @media screen and (min-width: 641px) {
    a[class*='lightcase-icon-'] {
      bottom: 50%;
      margin-bottom: -0.5em; }
      a[class*='lightcase-icon-']:hover,
      #lightcase-case:hover ~ a[class*='lightcase-icon-'] {
        opacity: 1; } }

#lightcase-overlay {
  display: none;
  width: 100%;
  min-height: 100%;
  position: fixed;
  z-index: 2000;
  top: -9999px;
  bottom: -9999px;
  left: 0;
  background: #333; }
  @media screen and (max-width: 640px) {
    #lightcase-overlay {
      opacity: 1 !important; } }
