@import '../variables';

.about-us__history{
	overflow: hidden;
	position: relative;
	padding-left: 25px;
	&:before {
		position: absolute;
		content: "";
		height:100%;
		width: 250px;
		right: 0;
		top: 0;
		background: linear-gradient(90deg, rgba(255,255,255,0.15) 0%, rgba(255,255,255,1) 100%);
		z-index: 2;
	}
	@media (max-width: $bp-sm - 1) {
		padding-left: 0;
		&:before {
			display: none;
		}
	}
}
.about-us__wrapper {
	display: flex;
	position: relative;
	align-items: center;
	z-index: 2;
	margin-bottom: 70px;
	flex-wrap: wrap;
	@media (max-width: $bp-xs - 1) {
		margin-bottom: 30px;
	}
}
.about-us__content {
	width: calc(45% - 70px);
	margin-right: 70px;
	@media (max-width: $bp-md - 1) {
		width: calc(55% - 70px);
	}
	@media (max-width: $bp-sm - 1) {
		margin-right: 0;
		width: 100%;
	}
}

.about-us__heading {
	text-align: left;
	font-size: 6.4rem;
	@media (max-width: $bp-md - 1) {
		font-size: 5rem;
	}
	@media (max-width: $bp-sm - 1) {
		font-size: 4.4rem;
		margin-bottom: 30px;
	}
	@media (max-width: $bp-xs - 1) {
		font-size: 2.8rem;
	}
}

.about-us__image {
	width: 55%;
	img{
		width: 100%;
		height:650px;
		object-fit:contain;
	}
	@media (max-width: $bp-md - 1) {
		width: 45%;
	}
	@media (max-width: $bp-sm - 1) {
		width: 100%;
		margin-bottom: 30px;
		img {
			height: 350px;
			display: block;
			margin: auto;
		}
	}
	@media (max-width: $bp-xxs - 1) {
		img {
			height: 250px;
		}
	}
}

.about-us{
	padding-top: 280px !important;
	@media (max-width: $bp-md - 1) {
		padding-top: 70px !important;
	}
	@media (max-width: $bp-xs - 1) {
		padding-bottom: 35px !important;
	}
}