@import '../variables';
$footer-background: $base-color;
$footer-padding: 30px;
$footer-copyright-padding: 20px;
$footer-col-width: 30%;
$footer-col-width-sm: 100%;
$footer-col-margin: 40px;
$text-color: #fff;
$heading-color: #fff;
$margin: 8px;
.footer {
	background: $footer-background;
	padding: $footer-padding 0 30px 0;
	@media (max-width: $bp-sm - 1) {
		padding-bottom: 30px;
		padding: $footer-padding 0 10px 0;
	}
}
.footer__wrapper {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	z-index: 2;
	margin-bottom: 32px;
}

//Logo
.footer__col {
	a,div{
		color:#fff;
	}
	&.--logo{
		margin-right: 64px;
	}
	&.--contact{
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-right: 40px;
	}
	&.--socials{
		display: flex;
		justify-content: center;
		flex-grow: 1;
	}
	@media (max-width: $bp-sm - 1) {
		width: 100%;
		&.--logo,&.--contact {
			margin-right: 0;
			justify-content: center;
			align-items: center;
			margin-bottom: 36px;
		}
		&.--socials{
			margin-bottom: 16px;
		}
	}
}
.footer__logo{
	margin-bottom: 16px;
	@media (max-width: $bp-sm - 1) {
		
			display: block;
			margin: auto;
			margin-bottom: 16px;
		
	}
}
.footer__logo-text{
	color: #fff;
	font-weight: 700;
	line-height: 1.4;
	font-size: 1.5rem;
	span{
		font-weight: 400;
		color: #fff;
		font-size: 1.3rem;
	}
	@media (max-width: $bp-sm - 1) {
		text-align: center;
	}
}
.footer__phone,.footer__email{
	display: flex;
	align-items: center;
	font-size: 2.4rem;
	font-weight: 700;
	&::before{
		display:block;
		content: "";
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		margin-right: 12px;
	}
	@media (max-width: $bp-xs - 1) {
		font-size: 2rem;
	}
}
.footer__phone{
	margin-bottom: 10px;
	&::before{
		background-image: url("../img/phone.svg");
		width: 24px;
		height: 32px;
	}
}
.footer__email{
	&::before{
		background-image: url("../img/email.svg");
		width: 24px;
		height: 20px;
	}
}
//Copyright
.footer-copyright {
	
	background-color: $footer-background;
	
}
.footer-copyright__content {
	text-align: center;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	border-top: 1px solid #fff;
	padding: 20px 0;
}
.footer-copyright__content-item {
	color: $text-color;
	a {
		margin-right: 15px;
		color: $text-color;
	}
	@media (max-width: $bp-sm - 1) {
		display: flex;
		flex-direction: column;
		width: 100%;

		&:last-child {
			order: -1;
			margin-bottom: 20px;
		}
		a {
			margin-right: 0;
			margin-bottom: 10px;
		}
	}
	
}

//footer menu
.footer__menu-wrapper {
	display: flex;
	flex-wrap: wrap;
	div, a {
		color: #fff;
		margin-right: 16px;
		font-size: 2rem;
	}
	ul {
		li {
			margin-bottom: $margin;
			
		}
	}
	@media (max-width: $bp-sm - 1) {
		width: 100%;
		text-align: center;
		flex-direction: column;
		div, a{
			margin-right: 0;
			margin-bottom: 16px;
		}
	}
	
}
.footer__contact-item{
	display: flex;
}
.footer__socials{
	display: block;
	margin-right: 16px;
	img{
		width: 30px;
		height: 30px;
	}
}