@import "../_variables.scss";

.mainContainer > section {
	padding: 70px 0;
	@media (max-width: $bp-xs - 1) {
		padding: 35px 0;
	}
	&.--background{
		background-color: #F5F6F8;
		position: relative;
	}
}

//Small-container
.--small-container {
	@media (min-width: $bp-md - 1) {
		max-width: 950px !important;
	}
}
.news__section{
	padding-bottom: 280px !important;
	@media (max-width: $bp-md - 1) {
		padding-bottom: 70px !important;
	}
	@media (max-width: $bp-xs - 1) {
		padding-bottom: 35px !important;
	}
}