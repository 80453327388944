@import "../variables";

// Search incon in header

#mainMenu {
	.search-button {
		display: block;
		width: 4rem;
		height: 4rem;
		cursor: pointer;
		margin: auto 10px;
		transition: all 200ms ease-in-out;
		border-radius: 4px;
		position: relative;
		transition: background 220ms ease-in-out;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-image: url('../img/search-icon.svg');
			background-size: 2.8rem auto;
			background-position: center;
			background-repeat: no-repeat;
			transition: transform 220ms ease-in-out;
		}
		&:hover {
			background-color: $base-color;
			&:before {
				filter: brightness(0) invert(1);
				transform: scale(.8);
			}
		}
	}
}

// Search modal
.ss-result {
	padding: 10px;
	margin: 20px 0 40px;
	h4 {
		font-size: 24px;
		display: inline-block;
		margin-bottom: 14px;
		//text-transform: uppercase;
		position: relative;
		font-weight: 700;
		&:after {
			content: "";
			display: block;
			width: 100%;
			height: 2px;
			background-color: $base-color;
			position: absolute;
			left: 0;
			bottom: -4px;
		}
		a {
			color: #333;
			&:hover{
				text-decoration: none;
			} 
		}
	}
	mark {
		font-weight: 600;
		display: inline-block;
		padding: 2px 6px;
		border-radius: 6px;
	}
	p {
		margin-bottom: 8px;
	}
	.ss-result__badge {
		display: inline-block;
		font-size: 11px;
		padding: 6px 11px;
		background-color: $base-color;
		color: #fff;
		margin-left: 10px;
		border-radius: 8px;
		vertical-align: top;
		&.badge-red {
			background-color: $base-color;
		}
		&.badge-blue {
			background-color: $green;
		}
	}
	.ss-result__link {
		font-weight: 600;
		position: relative;
		color: #333;
		text-decoration:none;
		&:after {
			content: "";
			display: block;
			width: 100%;
			height: 2px;
			background-color: $base-color;
			position: absolute;
			left: 0;
			bottom: -4px;
		}
		&:hover{
			text-decoration: none;
		}
	}
}

.search-modal {
	position: fixed;
	width: 100%;
	height: 0;
	overflow: hidden;
	top: 0;
	left: 0;
	z-index: 1080;
	background: #eee;
	justify-content: center;
	align-items: center;
	display: none;
	transition: all 250ms ease-in-out;
	&.shown {
		display: flex;
		animation: toFullHeight 300ms ease-in-out forwards;
	}
	input {
		background-color: #fff;
		font-size: 38px;
		font-weight: 700;
		padding: 30px 40px;
		border: none;
		border-radius: 60px;
		margin-bottom: 20px;
	}
	button.ss-search-form__submit {
		font-size: 22px;
		margin: 20px;
		border:none;
	}
	.close-modal.close-modal-btn {
		font-size: 22px;
		font-weight: 700;
		display: inline-block;
		color: #333;
		margin: 10px;
	}
	.close-modal.close-modal-cross {
		position: absolute;
		width: 80px;
		height: 80px!important;
		display: block;
		border: 3px solid #757575;
		top: 30px;
		right: 30px;
		border-radius: 50%;
		&:before,
		&:after {
			content: "";
			position: absolute;
			width: 60%;
			height: 60%;
			top: 50%;
			left: 50%;
			height: 3px;
			display: block;
			background-color: #757575;
			transform: translate(-50%, -50%) rotate(45deg);
		}
		&:after {
			transform: translate(-50%, -50%) rotate(-45deg);
		}
	}
	.ss-search-form__results.ss-search-form__results--visible {
		padding: 30px 30px 10px;
		background: #fff;
		border-radius: 30px;
		.ss-autocomplete-result {
			font-size: 2.2rem;
			font-weight: 700;
			text-decoration: underline;
			a {
				color: $text;
			}
			.ss-autocomplete-result__badge {
				display: inline-block;
				background-color: $base-color;
				font-size: 1.2rem;
				color: #fff;
				padding: 4px 12px;
				border-radius: 6px;
				//margin-left: 10px;
			}
		}
	}
}
@keyframes toFullHeight {
	0% {
		height: 0;
	}
	100% {
		height: 100%;
	}
}
@media (max-width: $bp-xs - 1) {
	.search-modal {
		input {
			display: table;
			width: 100%;
			max-width: 100%;
			font-size: 24px;
			padding: 20px 25px;
		}
		.close-modal.close-modal-cross {
			width: 50px;
			height: 50px;
		}
	}
}
@media (max-width: $bp-xxs - 1) {
	.search-modal {
		input {
			width: 100%;
			font-size: 18px;
		}
	}
}

@media (max-width: $nav-bp - 1) {
	#mainMenu .search-button {
		margin-left: auto;
	}
}
.pagination{
	display: flex;
	justify-content: center;
	li {
		&:before {
			display: none;
		}
	}
}
// .ss-autocomplete-result__title{
// 	display: flex;
// 	justify-content: space-between;
// 	width: 100%;
// }