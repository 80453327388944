@import "../../variables";

.header__intro{
	padding:5px 0;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	border-bottom: 1px solid $text;
	
}
.header__intro-item{
	margin-right: 10px;
	padding: 6px;
	position: relative;
	img{
		width: 20px;
		height: 20px;
	}
	& > span {
		position: absolute;
		top: 100%;
		right: 0;
		opacity: 0;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		padding: 7px 10px;
		z-index: 10;
		pointer-events: none;
		color: $text;
		display: table;
		white-space: nowrap;
	}
	&:hover{
		background-color: #F5F6F8;
		& > span {
			background-color: #F5F6F8;
			opacity: 1;
		}
	}
}
@media (min-width: $nav-bp) {
	#mainMenu {
		display: flex;
		align-items: center;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		li a {
			color: $text;
			line-height: 1.4;
		}
		.header__wrapper {
			flex-grow: 1;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 15px 0;
		}
		nav {
			flex-grow: 1;
			display: flex;
			// UL settings
			ul {
				// Main list
				display: flex;
				margin: auto;
				margin-right: 0;
				& > li {
					position: relative;
					&.w-submenu > a::after {
						content: "";
						width: .7rem;
						height: .7rem;
						border-bottom: 2px solid;
						border-right: 2px solid;
						margin-left: 8px;
						transform: rotate(45deg) translate(-3px);
					}
					&.w-submenu:hover > a:after {
						//transform: rotate(180deg);
					}
					a {
						display: flex;
						align-items: center;
						padding: 5px 10px;
						font-weight: 700;
						color: inherit;
						transition: none;
						margin-right: 10px;
						border: 2px solid transparent;
						border-radius: 30px;
						transition: all 250ms ease-in-out;
						&:hover {
							color: $base-color;
						}
					}
					@media screen and (max-width: $bp-lg - 1) {
						a {
							font-size: 1.7rem;
							padding: 5px 5px;
						}
					}
					@media screen and (max-width: $bp-md - 1) {
						a {
							font-size: 1.5rem;
							padding: 5px 0px;
							margin-right: 5px;
						}
					}
					// &.w-submenu > a:after {
					// 	content: " ";
					// 	display: inline-block;
					// 	padding: 0 0 0 3px;
					// 	width: 1em;
					// 	height: 1em;

					// 	background-size: 12px auto;
					// 	vertical-align: middle;
					// 	transition: all 250ms ease-in-out;
					// }
				}
				& > li > ul {
					// First level
					//background-color: $base-color;
					position: absolute;
					top: 100%;
					left: 0;
					min-width: 280px;

					width: auto;
					flex-direction: column;
					opacity: 0;
					pointer-events: none;
					height: auto;
					max-height: calc(100vh - 170px);
					border-radius: 15px;
					background-color: $base-color;
					overflow: auto;
					&:last-child {
						left: unset;
						right: 0;
					}
					a {
						transition: padding-left 160ms ease-in;
					}
					a:hover {
						padding-left: 26px;
					}
					& > li {
						border-radius: 0;
						color: #fff;
						position: relative;
						padding: 5px 0;
						border-top: 1px solid rgba(255, 255, 255, .35);
						a {
							display: block;
							border-radius: 0;
							color: #fff;
							padding-left: 14px;
							padding-right: 14px;
							&:hover {
								color: #fff;
								text-decoration: underline;
							}
						}
						&:hover > a {
							color: #fff;
						}
						&.w-submenu > a:after {
							content: "";
							position: absolute;
							right: 10px;
							left: auto;
							top: 1.2em;
							width: 10px;
							height: 10px;
							border-bottom: 3px solid #fff;
							border-right: 3px solid #ffffff;
							transform: translate(0, -50%) rotate(45deg);
							background-image: none;
							padding: 0;
							transition: transform 140ms ease-in;
						}
						&.w-submenu > a::before {
							content: "";
							position: absolute;
							right: 18px;
							left: auto;
							top: 1.2em;
							width: 16px;
							height: 2px;
							background-color: #fff;
							background-image: none;
							padding: 0;
							opacity: 0;
							transform: translate(-10px, 0);
							transition: all 140ms ease-in;
						}
						&.w-submenu:hover > a:after {
							transform: rotate(45deg);
						}
						&.w-submenu[data-status="clicked_once"] {
							& > a::after {
								transform: translate(-10px, -50%) rotate(-45deg);
							}
							& > a::before {
								opacity: 1;
								transform: translate(-10px);
							}

							a {
								background-color: $base-color;
								// &:hover {
								// 	background-color: $base-color;
								// }
								&:hover:after {
									transform: translate(0, -50%) rotate(-45deg);
								}
								&:hover:before {
									transform: translate(0);
								}
							}
						}
					}
					li > ul {
						// Sec level
						position: relative;
						display: none;
						opacity: 1;
						background-color: transparent;
						border-left: 9px solid #fff;
						border-radius: 0;
						& > li {
							width: 100%;
							color: #fff;
							a {
								position: relative;
								z-index: 10000;
								white-space: normal;
								color: #fff;
								padding-left: 20px;
								&:hover {
									background-color: $sec-color;
								}
							}
						}
						li ul {
							// Third level
							display: none;
							border-left: 12px solid #fff;
						}
					}
				}
				// Trigger for main menu
				li:hover,
				//li:focus-within,
				li:focus {
					& > ul {
						opacity: 1;
						pointer-events: all;
					}
				}
			}
		}
	}
}

#mainMenu {
	.header__logo {
		width: auto;
		max-width: 100%;
		height: 75px;
		
		img{
			display: block;
			margin-bottom: -32px;
			background: #fff;
			border-radius: 50%;
			width: 100%;
			height: 107px;
			object-fit: contain;
			margin-right: 8px;
		}
		@media screen and (max-width: $bp-md - 1) {
			height: 55px;

		}
		@media (max-width: $bp-sm - 1) {
			height:65px;
		}
		@media (max-width: $bp-xxs - 1) {
			img{
				margin-bottom: 0;
				height:74px;
			}
		}
		a{
			&:hover{
				text-decoration: none;
			}
		}
	}
	a{
		display:flex;
		align-items: center;
		height:100%;
		

	}
	.header__logo-text{
		font-size: 1.5rem;
		//width: 200px;
		flex-shrink: 0;
		line-height:1.2;
		color: $base-color;
		font-weight: 800;
		span{
			font-size: 1.3rem;
			color: $base-color;
			font-weight: 400;
		}
		@media screen and (max-width: $bp-lg - 1) {
			font-size: 1.4rem;
		}
		@media screen and (max-width: $bp-md - 1) {
			font-size: 1.2rem;
			span{
				font-size: 1rem;
			}
		}
	}
	
}
