@import "../../variables";
// Hamburger default
.header__wrapper {
	justify-content: space-between;
	label.header__hamburger {
		z-index: 9999;
		cursor: pointer;
		width: 40px;
		height: 50px;
		margin-right: 0;
		margin-left: 0;
		display: none;
		flex-direction: column;
		justify-content: center;
		align-items: flex-end;
		position: relative;
		order: 10;
		input {
			display: none;
		}
		span {
			display: inline-block;
			width: 30px;
			height: 4px;
			border-radius: 3px;
			background: $text;
			margin: 3px 0 3px auto;
			transition: all 200ms ease-in-out;
			&:nth-child(3) {
				width: 25px;
			}
		}
		&:hover span:nth-child(3) {
			width: 30px;
		}
	}
}

// Changes after menu is opened - Hamburger
@media (max-width: $nav-bp) {
	.header__wrapper label.header__hamburger {
		display: flex;
		transform-origin: center center;
	}
	body.menuOpen {
		header#mainMenu nav {
			opacity: 1;
			display: block;
		}
	}
	body.menuOpen {
		.header__wrapper label.header__hamburger {
			display: flex;
			span {
				background-color: #333;
				transform-origin: center center;
			}
			span:nth-child(3) {
				width: 0;
				opacity: 0;
			}
			span:nth-child(2) {
				// transform: rotate(45deg) translate(28px, -1px);
				transform: translate(0, 10px) rotate(45deg);
			}
			span:nth-child(4) {
				// transform: rotate(-45deg) translate(9px, 20px);
				transform: translate(0, -10px) rotate(-45deg);
			}
		}
	}
	// Display anim
	.header__wrapper nav ul li {
		ul {
			opacity: 1;
		}
		&:hover > ul,
		&:focus > ul {
			display: block;
			opacity: 1;
			z-index: 100;
		}
	}
}

// Changes after menu is opened - Hamburger
@media (max-width: $nav-bp) {
	.header__wrapper label.header__hamburger {
		display: flex;
		transform-origin: center center;
	}
	body.menuOpen {
		header#mainMenu nav {
			opacity: 1;
			display: block;
		}
	}
	body.menuOpen {
		.header__wrapper label.header__hamburger {
			display: flex;
			span {
				background-color: #333;
				transform-origin: center center;
			}
			span:nth-child(3) {
				width: 0;
				opacity: 0;
			}
			span:nth-child(2) {
				// transform: rotate(45deg) translate(28px, -1px);
				transform: translate(0, 10px) rotate(45deg);
			}
			span:nth-child(4) {
				// transform: rotate(-45deg) translate(9px, 20px);
				transform: translate(0, -10px) rotate(-45deg);
			}
		}
	}
	// Display anim
	.header__wrapper nav ul li {
		ul {
			opacity: 1;
		}
		&:hover > ul,
		&:focus > ul {
			display: block;
			opacity: 1;
			z-index: 100;
		}
	}
}
