@import "../_variables.scss";
a.fill-link {
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
}

$button-background: $base-color;
$button-color: #fff;
$button-border: #0080a2;
$button-border-radius: 10px;
$button-padding-left-right: 26px;
$button-padding-top-bottom: 16px;
$button-margin: 10px;
body section .btn-std,
.btn-std {
	background-color: $button-background;
	color: $button-color;
	//border: 7px solid $button-border;
	display: table;
	line-height: 1;
	margin: $button-margin auto;
	padding: $button-padding-top-bottom $button-padding-left-right;
	text-align: center;
	max-width: 100%;
	border-radius: $button-border-radius;
	position: relative;
	z-index: 2;
	transition: background-color 200ms ease-in-out, color 200ms ease-in-out !important;
	&.btn-reve {
		background: transparent;
		//color: $text;
	}
	&.btn-orange {
		color: $text;
		background: linear-gradient(270deg, rgba(255, 179, 71, 1) 0%, rgba(255, 204, 51, 1) 100%);
		//border: 7px solid #ffb347;
	}
	&.btn-left {
		margin: $button-margin auto $button-margin 0;
	}
	&.btn-right {
		margin: $button-margin 0 $button-margin auto;
	}
	&.btn-center {
		margin: $button-margin auto;
	}
	&:hover {
		background-color: $button-color;
		box-shadow: inset 0 0 0 2px $base-color;
		color: $button-background;
		&.btn-orange {
			background: #fff;
			color: $text;
		}
	}
}
