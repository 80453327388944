@import '../variables';
//Slick
.slick__arrow {
	position: absolute;
	left: 10px;
	top: calc(50% - 42px);
	width: 20px;
	height: 36px;
	background-image: url(../img/arrow.svg);
	background-color: transparent;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	z-index: 10;
	border: none;
	outline: none;
	transition: all 250ms ease-in-out;
	cursor: pointer;
	//transform: translate(0, 50%);
	&.slick__arrow-next {
		background-image: url(../img/arrow.svg);
		left: unset;
		right: 10px;
		transform: rotate(180deg);
	}
}

.partners__slick{
	position: relative;
}
.partners__logos{
	width: 90%;
	margin: auto;
	margin-bottom: 30px;
}
.slick-dots{
	display:flex;
	justify-content: center;
	align-items: center;
	li{
		position: relative;
		display: inline-block;
		height: 20px;
		width: 19px;
		background-color: transparent;
		border-radius: 50%;
		border: 3px solid $base-color;
		margin-bottom: 0 !important;
		flex-shrink: 0;
		transition: background-color 200ms ease-in-out;
		cursor: pointer;
		margin: 0 2px;
		&.slick-active {
			border: 3px solid $base-color;
			background-color: $base-color;
			height: 20px;
			width: 19px;
		}
		&:before{
			display: none;
		}
	}
	li button {
		display: none;
	}
}